<template>
    <el-dialog
            :title="title"
            :close-on-click-modal="false"
            append-to-body
            :visible.sync="visible">
        <el-form size="small" :model="inputForm" :rules="dataRule" v-loading="loading"  ref="inputForm" @keyup.enter.native="doSubmit()"
                 label-width="140px" @submit.native.prevent>
            <el-form-item label="任务名称：" prop="jobName">
                <el-input v-model="inputForm.jobName" placeholder="请输入任务名称"></el-input>
            </el-form-item>
            <el-form-item label="任务描述：" prop="description">
                <el-input v-model="inputForm.description" placeholder="请输入任务描述"></el-input>
            </el-form-item>

            <el-form-item label="执行类：" prop="jobClassName">
                <el-input v-model="inputForm.jobClassName" placeholder="请输入执行全类名"></el-input>
            </el-form-item>

            <el-form-item label="执行计划：" prop="cronExpression">
                <el-input v-model="inputForm.cronExpression" placeholder="请输入执行计划"></el-input>
            </el-form-item>

            <el-form-item label="开启任务：" prop="triggerState">
                <el-switch v-model="inputForm.triggerState" :active-value="0" :inactive-value="1"></el-switch>
            </el-form-item>

        </el-form>
        <span slot="footer" class="dialog-footer">
      <el-button size="small" @click="visible = false">取消</el-button>
      <el-button size="small" type="primary" @click="doSubmit()" v-noMoreClick>确定</el-button>
    </span>
    </el-dialog>
</template>

<script>
    export default {
        data () {
            return {
                visible: false,
                loading: false,
                title: '',
                method: '',
                inputForm: {
                    id : '',
                    jobName: '',
                    description: '',
                    jobClassName: '',
                    cronExpression: '',
                    openTask: '',
                    originalTaskName: ''
                },
                dataRule: {
                    jobName: [
                        {required: true, message: '任务不能为空', trigger: 'blur'}
                    ],
                    jobClassName: [
                        {required: true, message: '执行计划不能为空', trigger: 'change'}
                    ]
                    ,
                    cronExpression: [
                        {required: true, message: '执行类不能为空', trigger: 'change'}
                    ]
                }
            }
        },
        methods: {
            init (method, row) {
                this.method = method
                if (method === 'add') {
                    this.title = `新建`
                } else if (method === 'edit') {
                    this.title = '编辑'
                               
                    }
                this.visible = true
                this.$nextTick(() => {
                    this.$refs['inputForm'].resetFields()
                    if (method === 'edit') { // 修改
                        this.inputForm = this.recover(this.inputForm, row)
                        this.inputForm.originalTaskName = row.jobName
                    }
                })
            },
            // 表单提交
            doSubmit () {
                this.$refs['inputForm'].validate((valid) => {
                    if (valid) {
                        this.loading = true
                        this.inputForm.openTask = this.inputForm.triggerState == 0 ? true : false
                        if(this.title==`新建`){
                            this.$axios(this.api.rcPage.taskSave, this.inputForm, 'post').then(data => {
                                this.loading = false
                                if (data && data.status) {
                                    this.$message({
                                        message: '操作成功',
                                        type: 'success',
                                        duration: 1500
                                    })
                                    this.visible = false
                                    this.$emit('refreshDataList')
                                }
                            })
                        }else{
                            this.$axios(this.api.rcPage.taskUpdateById, this.inputForm, 'post').then(data => {
                                this.loading = false
                                if (data && data.status) {
                                    this.$message({
                                        message: '操作成功',
                                        type: 'success',
                                        duration: 1500
                                    })
                                    this.visible = false
                                    this.$emit('refreshDataList')
                                }
                            })
                        }
                    }
                })
            }
        }
    }
</script>
